<template>
  <b-tabs pills>
    <b-modal
      id="modal-mentor"
      ok-only
      centered
      :ok-title="$t('Pages.Account.Button.request_mentoring')"
      class="d-flex justify-content-center"
      @ok="requestJoinToMentoring"
    >
      <div class="text-center">
        <b-img
          class="w-50 text-center mx-auto"
          :src="require('@/assets/images/channels/blocked.svg')"
        />
      </div>
      <h2 class="text-center mt-1">
        {{ $t("Pages.Account.Title.become_mentor") }}
      </h2>
      <h5 class="text-center mt-1">
        {{ $t("Pages.Account.Title.become_mentor_text") }}
      </h5>
    </b-modal>

    <b-modal
      id="modal-change-mobile"
      ref="modal-change-mobile"
      hide-footer
      centered
      class="d-flex justify-content-center"
    >
      <b-form-group
        v-if="event.changeMobile"
        :label="$t('Pages.Account.Label.mobile_num')"
      >
        <b-input-group>
          <b-form-input
            v-model="optionsLocal.newMobile"
            placeholder="09*********"
            dir="ltr"
            type="text"
            :disabled="event.codeSended"
          />
          <b-input-group-append>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="sendMobileCode"
              :disabled="event.codeSended"
            >
              {{ $t("Pages.Account.Button.send_verif_code") }}
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
      <b-form-group
        v-if="event.codeSended"
        :label="$t('Pages.Account.Label.phone_verif_code')"
        :description="$t('Pages.Account.Title.check_verif_code')"
      >
        <b-input-group>
          <b-form-input
            v-model="optionsLocal.mobileCode"
            placeholder="****"
            dir="ltr"
            type="text"
          />
          <b-input-group-append>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="checkMobileCode"
            >
              {{ $t("Pages.Account.Button.confirm_phone") }}
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </b-modal>

    <b-modal
      id="modal-add-cardBank"
      ref="modal-add-cardBank"
      centered
      class="d-flex justify-content-center"
      ok-only
      :ok-title="$t('Pages.Account.Button.Add Card Number')"
      :title="$t('Pages.Account.Title.add_bank_card')"
      @ok="addNewCardNumber"
    >
      <b-form-group
        :label="$t('Pages.Account.Label.card_num')"
        label-for="account-name"
      >
        <b-form-input
          v-model="card_number"
          name="name"
          type="text"
          dir="ltr"
          required
          pattern="(\d{4}\s?){4}"
          maxlength="19"
          :placeholder="$t('Base.Placeholder.enter_card_num')"
          @keyup="addSpaceInCardNumber"
        />
      </b-form-group>
    </b-modal>

    <b-tab active>
      <!-- title -->
      <template #title>
        <feather-icon icon="UserIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">{{
          $t("Pages.Account.Title.main_info")
        }}</span>
      </template>

      <b-card>
        <!-- media -->
        <b-media no-body>
          <b-media-aside>
            <b-link>
              <b-img
                ref="previewEl"
                rounded
                v-if="newAvatar"
                :src="newAvatar"
                height="80"
              />
              <b-img
                ref="previewEl"
                rounded
                v-else
                :src="optionsLocal.avatar"
                height="80"
              />
            </b-link>
            <!--/ avatar -->
          </b-media-aside>

          <b-media-body class="mt-75 ml-75">
            <!-- upload button -->
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              size="sm"
              class="mb-75 mr-75"
              @click="$refs.refInputEl.$el.click()"
            >
              {{ $t("Pages.Account.Button.select_img") }}
            </b-button>
            <b-form-file
              ref="refInputEl"
              v-model="profileFile"
              accept=".jpg, .png, .gif"
              :hidden="true"
              plain
              @input="selectAvatar"
            />
            <!--/ upload button -->

            <b-card-text
              >{{ $t("Pages.Account.Title.auth_format") }}: JPG, PNG
            </b-card-text>
          </b-media-body>
          <!-- <div>
            <b-button v-if="PermissionRequestJoinToMentoring && status_mentoring == 'no_request'"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="warning" size="lg" class="mb-75 mr-75"
              v-b-modal.modal-mentor>
              {{ $t("Pages.Account.Button.Become A Mentor") }}
            </b-button>
            <b-button v-else-if="PermissionRequestJoinToMentoring && status_mentoring == 'request'"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="warning" size="lg" class="mb-75 mr-75">
              {{ $t("Pages.Account.Button.Become A Mentor Requested") }}
            </b-button>
            <b-button v-else-if="PermissionRequestJoinToMentoring && status_mentoring == 'reject'"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger" size="lg" class="mb-75 mr-75">
              {{ $t("Pages.Account.Button.Become A Mentor Reject") }}
            </b-button>
            <b-button v-else-if="PermissionRequestJoinToMentoring && status_mentoring == 'confirm'"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success" size="lg" class="mb-75 mr-75">
              {{ $t("Pages.Account.Button.Become A Mentor Confirm") }}
            </b-button>
          </div> -->
        </b-media>
        <!--/ media -->

        <!-- form -->
        <b-form class="mt-2">
          <b-row>
            <b-col sm="6">
              <b-form-group :label="$t('Pages.Account.Label.full_name')">
                <b-input-group>
                  <b-form-input
                    v-model="optionsLocal.fullName"
                    name="name"
                    type="text"
                    :placeholder="$t('Base.Placeholder.enter_full_name')"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col sm="6">
              <b-form-group
                :label="$t('Pages.Account.Label.ref_code')"
                :description="base_url + optionsLocal.referral_token"
              >
                <b-input-group>
                  <b-form-input
                    v-model="optionsLocal.referral_token"
                    type="text"
                    @keydown="filterReferralToken"
                    @keyup="checkAvailableReferralToken"
                    :placeholder="$t('Base.Placeholder.ref_code')"
                    :class="
                      checkAvailableReferralCode == 'available'
                        ? 'border-success'
                        : checkAvailableReferralCode == 'unavailable'
                        ? 'border-danger'
                        : ''
                    "
                  />
                  <b-input-group-append>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      @click="copyReferralCode"
                    >
                      {{ $t("Pages.Account.Button.Copy") }}
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col sm="6">
              <b-form-group :label="$t('Pages.Account.Label.email_address')">
                <b-input-group>
                  <b-form-input
                    v-model="optionsLocal.email"
                    name="email"
                    disabled
                    type="email"
                    :placeholder="$t('Base.Placeholder.enter_email')"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col sm="6">
              <b-form-group
                v-if="optionsLocal.mobile"
                :label="$t('Pages.Account.Label.mobile_num')"
              >
                <b-input-group>
                  <b-form-input
                    v-model="optionsLocal.mobile"
                    placeholder="09*********"
                    dir="ltr"
                    disabled
                    type="number"
                  />
                  <b-input-group-append>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      @click="changeMobile"
                    >
                      {{ $t("Pages.Account.Button.change_mobile_num") }}
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
              <b-form-group
                v-else
                :label="$t('Pages.Account.Label.new_mobile')"
              >
                <b-input-group>
                  <b-form-input
                    v-model="optionsLocal.newMobile"
                    placeholder="09*********"
                    dir="ltr"
                    type="number"
                  />
                  <b-input-group-append>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      @click="changeMobile"
                    >
                      {{ $t("Pages.Account.Button.send_verif_code") }}
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>

            <div class="col-12 border-top my-2" v-if="membership.id == 4"></div>

            <b-col sm="6" v-if="membership.id == 4">
              <b-form-group
                :label="$t('Pages.Account.Label.national_code')"
                :description="$t('Base.Placeholder.becare_register')"
              >
                <b-input-group>
                  <b-form-input
                    v-model="optionsLocal.national_code"
                    name="national_code"
                    :disabled="nationalCodeDisabled"
                    type="number"
                    :placeholder="$t('Base.Placeholder.enter_national_code')"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col sm="6" v-if="false">
              <b-form-group
                :label="$t('Pages.Account.Label.postal_code')"
                :description="$t('Base.Placeholder.becare_register')"
              >
                <b-input-group>
                  <b-form-input
                    v-model="optionsLocal.postal_code"
                    name="postal_code"
                    :disabled="postalCodeDisabled"
                    type="number"
                    :placeholder="$t('Base.Placeholder.enter_postal_code')"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col sm="6" v-if="membership.id == 4">
              <b-form-group
                :label="$t('Pages.Account.Label.birth_date')"
                :description="$t('Base.Placeholder.becare_register')"
              >
                <b-input-group>
                  <date-picker
                    :locale="$i18n.locale == 'fa'?'fa':'en'"
                    class="w-100"
                    v-model="optionsLocal.birth_date"
                    :disabled="birthDateDisabled"
                    :placeholder="$t('Base.Placeholder.enter_birth_date')"
                  />
               
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col cols="12" class="d-flex justify-content-end">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-2 mr-1"
                @click="save"
              >
                {{ $t("Pages.Account.Button.save_changes") }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-card>
    </b-tab>

    <b-tab lazy>
      <template #title>
        <feather-icon icon="KeyIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">{{
          $t("Pages.Account.Title.change_pass")
        }}</span>
      </template>

      <b-card>
        <b-alert variant="info" show v-if="!sendEmail">
          <div class="alert-body">
            <span>{{ $t("Pages.Account.Title.req_pass_recovery") }}</span>
            <b-button
              class="mr-2 ml-2"
              type="submit"
              variant="danger"
              @click="sendRecoveryPasswordLink"
            >
              {{ $t("Pages.Account.Button.send_link_pass") }}
            </b-button>
          </div>
        </b-alert>
        <b-alert variant="success" show v-if="sendEmail">
          <div class="alert-body">
            <span>{{ $t("Base.Alert.success_recovery_pass") }}</span>
          </div>
        </b-alert>
        <b-form class="mt-2">
          <b-row>
            <b-col sm="6">
              <b-form-group
                :label="$t('Pages.Account.Label.current_pass')"
                label-for="account-name"
              >
                <b-form-input
                  v-model="optionsLocal.password"
                  name="name"
                  type="password"
                  :placeholder="$t('Base.Placeholder.enter_current_pass')"
                />
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group
                :label="$t('Pages.Account.Label.new_pass')"
                label-for="account-name"
              >
                <b-form-input
                  v-model="optionsLocal.newPassword"
                  name="name"
                  type="password"
                  :placeholder="$t('Base.Placeholder.enter_new_pass')"
                />
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group
                :label="$t('Pages.Account.Label.re_enter_new_pass')"
                label-for="account-name"
              >
                <b-form-input
                  v-model="optionsLocal.repeatNewPassword"
                  name="name"
                  type="password"
                  :placeholder="$t('Base.Placeholder.again_enter_new_pass')"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" class="d-flex justify-content-end">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-2 mr-1"
                @click="changePassword"
              >
                {{ $t("Pages.Account.Button.save_changes") }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-card>
    </b-tab>

    <b-tab lazy v-if="membership.id == 4">
      <template #title>
        <feather-icon icon="FlagIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">{{
          $t("Pages.Account.Title.Addresses")
        }}</span>
      </template>
      <b-card>
        <b-row>
          <b-col cols="12" class="alert-area">
            <b-alert show fade class="mb-0 alert-width" variant="info">
              <div class="alert-body">
                <span>{{ $t("Pages.Account.Title.default_address_msg") }}</span>
              </div>
            </b-alert>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              class="add-btn"
              variant="primary"
              v-b-modal.addAddressModal
            >
              <feather-icon icon="PlusIcon" class="mr-25" />
              <span>{{ $t("Pages.Account.Button.add_new_address") }}</span>
            </b-button>
          </b-col>
        </b-row>
      </b-card>
      <!-- Row Loop -->
      <b-row>
        <b-form
          class="col-md-6 col-12 addressCart"
          v-for="item in userAddresses.addresses"
          :id="item.id.toString()"
          :key="item.id"
          ref="row"
          @click="updateDefaultAddress($event, item.id)"
        >
          <div
            :class="
              userAddresses.default == item.id ? 'card border-info' : 'card'
            "
          >
            <div class="row card-body">
              <b-col cols="12" class="mb-2 card-title">
                <p>
                  {{ $t("Pages.Account.Label.Address") }}:
                  <span v-text="item.name"></span
                  ><span v-if="userAddresses.default == item.id">
                    {{ $t("Pages.Account.Label.default_address") }}
                  </span>
                </p>
                <hr />
              </b-col>
              <b-col cols="12">
                <b-form-group
                  :label="$t('Pages.Account.Label.Country')"
                  :label-for="`country${item.id}`"
                >
                  <b-form-input
                    v-model="item.country.name"
                    :id="`country${item.id}`"
                    type="text"
                    disabled
                  />
                </b-form-group>
              </b-col>

              <b-col cols="6">
                <b-form-group
                  :label="$t('Pages.Account.Label.Province')"
                  :label-for="`province${item.id}`"
                  class="w-100"
                >
                  <b-form-input
                    v-model="item.province.name"
                    :id="`province${item.id}`"
                    type="text"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group
                  :label="$t('Pages.Account.Label.City')"
                  :label-for="`city${item.id}`"
                  class="w-100"
                >
                  <b-form-input
                    v-model="item.city.name"
                    :id="`city${item.id}`"
                    type="text"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group
                  :label="$t('Pages.Account.Label.postal_code')"
                  :label-for="`postalCode${item.id}`"
                  class="w-100"
                >
                  <b-form-input
                    v-model="item.postal_code"
                    :id="`postalCode${item.id}`"
                    type="text"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group
                  :label="$t('Pages.Account.Label.num_plates')"
                  :label-for="`numberPlates${item.id}`"
                  class="w-100"
                >
                  <b-form-input
                    v-model="item.number_plates"
                    :id="`numberPlates${item.id}`"
                    type="text"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  :label="$t('Pages.Account.Label.Address')"
                  :label-for="`address${item.id}`"
                  class="w-100"
                >
                  <b-form-textarea
                    v-model="item.address"
                    :id="`address${item.id}`"
                    rows="3"
                    type="text"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" class="mb-50 text-center mt-2">
                <b-button
                  variant="outline-danger"
                  class="btn btn-sm mr-2"
                  @click="deleteAddress(item.id)"
                >
                  <span>{{ $t("Pages.Account.Button.del_address") }}</span>
                </b-button>
                <b-button
                  variant="outline-warning"
                  class="btn btn-sm"
                  @click="editUserAddress(item.id)"
                >
                  <span>{{ $t("Pages.Account.Button.edit_address") }}</span>
                </b-button>
              </b-col>
            </div>
          </div>
        </b-form>
      </b-row>

      <b-modal
        id="addAddressModal"
        ref="address-modal"
        centered
        :ok-title="$t('Pages.Account.Button.add_address')"
        :cancel-title="$t('Pages.Account.Button.Cancel')"
        @ok="addAddress"
        @hidden="resetAddressForm"
      >
        <b-form @submit.prevent>
          <b-row>
            <b-col cols="12">
              <b-form-group
                :label="$t('Pages.Account.Label.name_address')"
                label-for="name-address"
              >
                <b-form-input
                  id="name-address"
                  v-model="nameAddress"
                  :placeholder="$t('Pages.Account.Label.name_address')"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                :label="$t('Pages.Account.Label.Country')"
                label-for="country"
              >
                <b-form-select
                  v-model="country"
                  :options="addressDatas.countries"
                  :state="country === 1"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                :label="$t('Pages.Account.Label.Province')"
                label-for="province"
              >
                <b-form-select
                  id="province"
                  v-model="province"
                  :options="addressDatas.provinces[country]"
                  :state="province !== null"
                  @change="city = null"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                :label="$t('Pages.Account.Label.City')"
                label-for="city"
              >
                <b-form-select
                  id="city"
                  v-model="city"
                  :options="addressDatas.cities[province]"
                  :state="city !== null"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                :label="$t('Pages.Account.Label.postal_code')"
                label-for="postal-code"
              >
                <b-form-input
                  id="postal-code"
                  v-model="postalCode"
                  :placeholder="$t('Base.Placeholder.postal_code')"
                  maxlength="10"
                  :state="
                    postalCode !== null &&
                    parseInt(postalCode).toString().length === 10
                  "
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                :label="$t('Pages.Account.Label.num_plates')"
                label-for="number-plates"
              >
                <b-form-input
                  id="number-plates"
                  v-model="numberPlates"
                  :placeholder="$t('Base.Placeholder.num_plates')"
                  :state="
                    numberPlates !== null && numberPlates.toString().length >= 1
                  "
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                :label="$t('Pages.Account.Label.Address')"
                label-for="address"
              >
                <b-form-textarea
                  id="address"
                  v-model="address"
                  :placeholder="$t('Base.Placeholder.Address')"
                  rows="3"
                  :state="address.length >= 10"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-modal>
      <b-modal
        v-if="editAddress"
        id="editAddressModal"
        ref="edit-address-modal"
        centered
        :ok-title="$t('Pages.Account.Button.edit_address')"
        :cancel-title="$t('Pages.Account.Button.Cancel')"
        @ok="updateUserAddress"
        @hidden="resetEditAddressForm"
      >
        <b-form @submit.prevent>
          <b-row>
            <b-col cols="12">
              <b-form-group
                :label="$t('Pages.Account.Label.name_address')"
                label-for="name-address"
              >
                <b-form-input
                  id="name-address"
                  v-model="editAddress.name"
                  :placeholder="$t('Base.Placeholder.name_address')"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                :label="$t('Pages.Account.Label.Country')"
                label-for="editCountry"
              >
                <b-form-select
                  v-model="editAddress.country"
                  :options="addressDatas.countries"
                  :state="editAddress.country === 1"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                :label="$t('Pages.Account.Label.Province')"
                label-for="editProvince"
              >
                <b-form-select
                  id="editProvince"
                  v-model="editAddress.province"
                  :options="addressDatas.provinces[editAddress.country]"
                  :state="editAddress.province !== null"
                  @change="editAddress.city = null"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                :label="$t('Pages.Account.Label.City')"
                label-for="editCity"
              >
                <b-form-select
                  id="editCity"
                  v-model="editAddress.city"
                  :options="addressDatas.cities[editAddress.province]"
                  :state="editAddress.city !== null"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                :label="$t('Pages.Account.Label.postal_code')"
                label-for="edit-postal-code"
              >
                <b-form-input
                  id="edit-postal-code"
                  v-model="editAddress.postalCode"
                  :placeholder="$t('Base.Placeholder.postal_code')"
                  maxlength="10"
                  :state="
                    editAddress.postalCode !== null &&
                    parseInt(editAddress.postalCode).toString().length === 10
                  "
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                :label="$t('Pages.Account.Label.num_plates')"
                label-for="edit-number-plates"
              >
                <b-form-input
                  id="edit-number-plates"
                  v-model="editAddress.numberPlates"
                  :placeholder="$t('Base.Placeholder.num_plates')"
                  :state="
                    editAddress.numberPlates !== null &&
                    editAddress.numberPlates.toString().length >= 1
                  "
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                :label="$t('Pages.Account.Label.Address')"
                label-for="edit-address"
              >
                <b-form-textarea
                  id="edit-address"
                  v-model="editAddress.address"
                  :placeholder="$t('Base.Placeholder.Address')"
                  rows="3"
                  :state="editAddress.address.length >= 10"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-modal>
    </b-tab>

    <b-tab lazy v-if="membership.id == 4">
      <!-- title -->
      <template #title>
        <feather-icon icon="CreditCardIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">{{
          $t("Pages.Account.Title.bank_info")
        }}</span>
      </template>

      <b-card>
        <b-row>
          <b-col cols="12" class="alert-area">
            <b-alert show fade class="mb-0 alert-width" variant="info">
              <div class="alert-body">
                <span>{{ $t("Pages.Account.Title.default_card_msg") }}</span>
              </div>
            </b-alert>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="add-btn"
              @click="openModalAddNewCardNumber"
            >
              <feather-icon icon="PlusIcon" class="mr-25" />
              <span>{{ $t("Pages.Account.Button.add_new_bank") }}</span>
            </b-button>
          </b-col>
        </b-row>
      </b-card>

      <b-row>
        <b-form
          class="col-md-4 col-sm-6 col-12 addressCart"
          v-for="(item, index) in items"
          :id="item.id"
          :key="item.id"
          ref="row"
          @click="updateDefaultBank(item.id)"
        >
          <div
            :class="default_bank_id == item.id ? 'card border-info' : 'card'"
          >
            <div class="row card-body">
              <b-col cols="12" class="mb-2 card-title">
                <p>
                  <span v-text="item.bank_name"></span>
                  <span v-if="default_bank_id == item.id">(پیش فرض)</span>
                </p>
                <hr />
              </b-col>
              <b-col cols="6">
                <b-form-group
                  :label="$t('Pages.Account.Label.name_owner')"
                  label-for="account-name"
                >
                  <b-form-input
                    v-model="item['name_owner']"
                    name="name"
                    type="text"
                    :placeholder="$t('Base.Placeholder.enter_name_owner')"
                    disabled
                  />
                </b-form-group>
              </b-col>

              <b-col cols="6">
                <b-form-group
                  :label="$t('Pages.Account.Label.account_num')"
                  label-for="account-name"
                  class="w-100"
                >
                  <b-form-input
                    v-model="item['bank_account_number']"
                    name="name"
                    type="number"
                    :placeholder="$t('Base.Placeholder.enter_account_num')"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group
                  :label="$t('Pages.Account.Label.card_num')"
                  label-for="account-name"
                >
                  <b-form-input
                    v-model="item['bank_card_number']"
                    name="name"
                    type="number"
                    :placeholder="$t('Base.Placeholder.enter_card_num')"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group
                  :label="$t('Pages.Account.Label.sheba_num')"
                  label-for="account-name"
                >
                  <b-form-input
                    v-model="item['bank_sheba_number']"
                    name="name"
                    type="number"
                    :placeholder="$t('Base.Placeholder.enter_sheba_num')"
                    disabled
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12" class="mb-50 text-center mt-sm-0 mt-2">
                <b-button
                  variant="outline-danger"
                  class="btn btn-sm mt-0 mt-md-2"
                  @click="deleteCardNumber(item.id)"
                >
                  <span>{{ $t("Pages.Account.Button.del_bank") }}</span>
                </b-button>
              </b-col>
            </div>
          </div>
        </b-form>
      </b-row>
    </b-tab>

    <b-tab lazy @click="getList">
      <template #title>
        <feather-icon icon="StarIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">{{
          $t("Pages.Account.Title.purchase_records")
        }}</span>
      </template>
      <b-row>
        <b-col lg="12" sm="12">
          <b-card no-body class="card-company-table">
            <b-overlay
              :show="!tableData"
              spinner-variant="primary"
              variant="transparent"
              spinner-type="grow"
              spinner-small
              rounded="sm"
            >
              <b-table
                :items="tableData"
                responsive
                :fields="fields"
                show-empty
                :empty-text="$t('Pages.Account.Table.not_purchase_sub')"
                class="mb-0"
              >
                <!-- company -->
                <template #cell(name_membership)="data">
                  <div class="d-flex align-items-center">
                    <b-avatar rounded size="32" variant="light-company">
                      <b-img
                        v-if="data.item.id == 1"
                        :src="data.item.plan.icon"
                      />
                    </b-avatar>
                    <div class="ml-1">
                      <div class="font-weight-bolder">
                        {{ data.item.plan.name }}
                      </div>
                    </div>
                  </div>
                </template>

                <!-- views -->
                <template #cell(start_date)="data">
                  <div class="d-flex flex-column">
                    <span class="font-weight-bolder mb-25">{{
                      MyDate.getFormatDate(data.item.start_date)
                    }}</span>
                  </div>
                </template>

                <!-- sales -->
                <template #cell(expire_date)="data">
                  <div class="d-flex align-items-center">
                    <span class="font-weight-bolder mr-1">{{
                      MyDate.getFormatDate(data.item.expire_date)
                    }}</span>
                  </div>
                </template>

                <!-- status -->
                <template #cell(price)="data">
                  <div class="d-flex align-items-center">
                    <span class="font-weight-bolder mr-1">{{
                      data.item.plan.price.toLocaleString() +
                      " " +
                      $t("Pages.Plan.Title.Tooman")
                    }}</span>
                  </div>
                </template>

                <template #cell(deposit_amount)="data">
                  <div class="d-flex align-items-center">
                    <span
                      class="font-weight-bolder mr-1"
                      v-if="data.item.payment"
                      >{{
                        data.item.payment.amount.toLocaleString() +
                        " " +
                        $t("Pages.Plan.Title.Tooman")
                      }}</span
                    >
                    <span class="font-weight-bolder mr-1" v-else>{{
                      $t("Pages.Plan.Title.Gift")
                    }}</span>
                  </div>
                </template>
              </b-table>
            </b-overlay>
          </b-card>
        </b-col>
      </b-row>
    </b-tab>

    <b-tab lazy @click="getListReferral">
      <template #title>
        <feather-icon icon="Share2Icon" size="18" class="mr-50" />
        <span class="font-weight-bold">{{
          $t("Pages.Account.Title.introduced_people")
        }}</span>
      </template>
      <b-row>
        <b-col lg="12" sm="12">
          <b-card no-body class="card-company-table">
            <b-overlay
              :show="!referral_users"
              spinner-variant="primary"
              variant="transparent"
              spinner-type="grow"
              spinner-small
              rounded="sm"
            >
              <b-table
                :items="referral_users"
                responsive
                :fields="referral_fields"
                show-empty
                :empty-text="$t('Pages.Account.Table.no_one_reg_link')"
                class="mb-0"
              >
                <template #cell(name)="data">
                  <div class="d-flex align-items-center">
                    <b-avatar
                      v-if="data.item.avatar"
                      rounded
                      size="32"
                      variant="light-company"
                    >
                      <b-img
                        :src="data.item.avatar"
                        width="32px"
                        alt="Medal Pic"
                      />
                    </b-avatar>
                    <b-avatar
                      v-else
                      :text="data.item.name"
                      rounded
                      size="32"
                      variant="light-company"
                    ></b-avatar>
                    <div class="ml-1">
                      <div class="font-weight-bolder">
                        {{ data.item.name }}
                      </div>
                    </div>
                  </div>
                </template>

                <template #cell(email)="data">
                  <div class="d-flex flex-column">
                    <span class="font-weight-bolder mb-25">{{
                      data.item.email
                    }}</span>
                  </div>
                </template>

                <template #cell(mobile)="data">
                  <div class="d-flex flex-column">
                    <span class="font-weight-bolder mb-25">{{
                      data.item.mobile
                    }}</span>
                  </div>
                </template>

                <template #cell(date_join)="data">
                  <div class="d-flex align-items-center">
                    <span class="font-weight-bolder mr-1">{{
                      MyDate.getFormatDate(data.item.created_at)
                    }}</span>
                  </div>
                </template>
              </b-table>
            </b-overlay>
          </b-card>
        </b-col>
      </b-row>
    </b-tab>

    <b-tab lazy @click="getListDevices">
      <template #title>
        <feather-icon icon="LogInIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">{{
          $t("Pages.Account.Title.login_history")
        }}</span>
      </template>
      <b-row class="match-height">
        <b-col lg="8" sm="8">
          <section class="">
            <b-card v-for="device in devices" :key="device.id">
              <row class="d-flex justify-content-center align-content-center">
                <b-col cols="2"
                  ><b-img
                    style="width: 100px"
                    :src="
                      require('@/assets/images/profile/desktop_computer.svg')
                    "
                /></b-col>
                <b-col cols="5">
                  <h4>
                    {{
                      $t("Pages.Account.Title.name_device : {name}", {
                        name: device.platform,
                      })
                    }}
                    <b-badge
                      v-if="
                        $VueCookies.get('mastertube').split('|')[0] == device.id
                      "
                      variant="warning"
                      >{{ $t("Pages.Account.Title.same_device") }}</b-badge
                    >
                  </h4>
                  <h4>
                    {{
                      $t("Pages.Account.Title.Browser : {name}", {
                        name: device.browser,
                      })
                    }}
                  </h4>
                  <h4>
                    {{
                      $t("Pages.Account.Title.ip_address : {name}", {
                        name: device.ip,
                      })
                    }}
                  </h4>
                </b-col>
                <b-col cols="3">
                  <h4>
                    {{
                      $t("Pages.Account.Title.Region : {name}", {
                        name: device.region,
                      })
                    }}
                  </h4>
                  <h4>
                    {{
                      $t("Pages.Account.Title.Country : {name}", {
                        name: device.country,
                      })
                    }}
                  </h4>
                  <h4>
                    {{
                      $t("Pages.Account.Title.City : {name}", {
                        name: device.city,
                      })
                    }}
                  </h4>
                </b-col>
                <b-col cols="2">
                  <b-button
                    class="mx-auto w-75 px-0"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="warning"
                    @click="DeleteDevice(device.id)"
                    >{{ $t("Pages.Account.Button.expulsion_user") }}</b-button
                  >
                  <b-button
                    class="mx-auto w-75 px-0 my-1"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="success"
                    @click="ApprovedDevice(device.id)"
                    >{{ $t("Pages.Account.Button.Approved") }}</b-button
                  >
                </b-col>
              </row>
            </b-card>
          </section>
        </b-col>
        <b-col lg="4" sm="4" v-if="devices.length > 0">
          <b-card>
            <div>
              <b-button
                class="mx-auto w-100 mb-1 px-0"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="LogoutOthers"
                :disabled="devices.length < 2"
                >{{ $t("Pages.Account.Button.logout_others") }}</b-button
              >
              <b-button
                class="mx-auto w-100 px-0"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="LogoutAll"
                :disabled="devices.length < 2"
                >{{ $t("Pages.Account.Button.logout_all") }}</b-button
              >
            </div>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12" sm="12">
          <b-card no-body class="card-company-table">
            <b-card-header>
              <b-card-title>{{
                $t("Pages.Account.Title.recent_logins")
              }}</b-card-title>
            </b-card-header>
            <b-card-body>
              <b-overlay
                :show="!devices_log"
                spinner-variant="primary"
                variant="transparent"
                spinner-type="grow"
                spinner-small
                rounded="sm"
              >
                <b-table
                  :items="devices_log"
                  responsive
                  :fields="devices_log_fields"
                  show-empty
                  key="id"
                  :empty-text="$t('Pages.Account.Table.recent_logins_empty')"
                  class="mb-0"
                >
                  <template #cell(device_type)="data">
                    <div class="d-flex align-items-center">
                      <div class="ml-1">
                        <div class="font-weight-bolder">
                          {{ data.item.device_type }}
                          <b-badge
                            v-if="
                              $VueCookies.get('mastertube').split('|')[0] ==
                              data.item.id
                            "
                            variant="warning"
                            >{{
                              $t("Pages.Account.Title.same_device")
                            }}</b-badge
                          >
                        </div>
                      </div>
                    </div>
                  </template>

                  <template #cell(type)="data">
                    <div class="d-flex align-items-center">
                      <div class="ml-1">
                        <div class="font-weight-bolder">
                          {{ data.item.platform }}
                        </div>
                      </div>
                    </div>
                  </template>

                  <template #cell(ip)="data">
                    <div class="d-flex flex-column">
                      <span class="font-weight-bolder mb-25">{{
                        data.item.ip
                      }}</span>
                    </div>
                  </template>

                  <template #cell(city)="data">
                    <div class="d-flex align-items-center">
                      <span class="font-weight-bolder mr-1">{{
                        data.item.city
                      }}</span>
                    </div>
                  </template>

                  <template #cell(region)="data">
                    <div class="d-flex align-items-center">
                      <span class="font-weight-bolder mr-1">{{
                        data.item.region
                      }}</span>
                    </div>
                  </template>

                  <template #cell(country)="data">
                    <div class="d-flex align-items-center">
                      <span class="font-weight-bolder mr-1">{{
                        data.item.country
                      }}</span>
                    </div>
                  </template>

                  <template #cell(date)="data">
                    <div class="d-flex align-items-center">
                      <span class="font-weight-bolder mr-1">{{
                        MyDate.getFormatDateTime(data.item.created_at)
                      }}</span>
                    </div>
                  </template>

                  <template #cell(status)="data">
                    <div class="d-flex align-items-center">
                      <span
                        class="font-weight-bolder mr-1"
                        v-if="data.item.status"
                        ><b-badge variant="success">{{
                          $t("Pages.Account.Table.Approved")
                        }}</b-badge></span
                      >
                      <span class="font-weight-bolder mr-1" v-else
                        ><b-badge variant="danger">{{
                          $t("Pages.Account.Table.Unknown")
                        }}</b-badge></span
                      >
                    </div>
                  </template>

                  <template #cell(actions)="data">
                    <div class="d-flex align-items-center">
                      <feather-icon
                        icon="XCircleIcon"
                        size="16"
                        class="mx-1 cursor-pointer text-danger"
                        v-if="!data.item.deleted_at"
                        @click="DeleteDevice(data.item.id)"
                      />
                    </div>
                  </template>
                </b-table>
              </b-overlay>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-tab>
  </b-tabs>
</template>

<script>
import {
  BTabs,
  BTab,
  BFormFile,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormTextarea,
  BRow,
  BCol,
  BAlert,
  BCard,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
  BFormRadio,
  BTable,
  BCardBody,
  BOverlay,
  BCardHeader,
  BCardTitle,
  BBadge,
  BInputGroupAppend,
  BInputGroup,
  BModal,
  VBModal,
  BAvatar
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import MyDate from "@core/components/date/MyDate.vue";
import VuePersianDatetimePicker from "vue-persian-datetime-picker";
var moment = require("moment-jalaali");

export default {
  components: {
    BTabs,
    BTab,
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormTextarea,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BFormRadio,
    BTable,
    BCardBody,
    BOverlay,
    BCardHeader,
    BCardTitle,
    BBadge,
    BInputGroupAppend,
    BInputGroup,
    BModal,
    VBModal,
    BAvatar,
    datePicker: VuePersianDatetimePicker,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      MyDate,
      tableData: null,
      fields: [
        {
          key: "name_membership",
          label: this.$t("Pages.Account.Table.membership_title"),
        },
        { key: "start_date", label: this.$t("Pages.Account.Table.from_date") },
        { key: "expire_date", label: this.$t("Pages.Account.Table.to_date") },
        { key: "price", label: this.$t("Pages.Account.Table.Price") },
        {
          key: "deposit_amount",
          label: this.$t("Pages.Account.Table.deposit_amount"),
        },
      ],
      showTableData: false,
      nationalCodeDisabled: false,
      postalCodeDisabled: false,
      birthDateDisabled: false,
      optionsLocal: {
        avatar: "",
        national_code: "",
        postal_code: "",
        birth_date: "",
        fullName: "",
        email: "",
        mobile: "",
        referral_token: "",
        newMobile: "",
        mobile: "",
        mobileCode: "",
        password: "",
        newPassword: "",
        repeatNewPassword: "",
      },
      profileFile: null,
      event: {
        changeMobile: false,
        codeSended: false,
      },
      items: [],
      card_number: "",
      default_bank_id: null,
      nextTodoId: 2,
      sendEmail: false,
      devices: [],
      devices_log: [],
      devices_log_fields: [
        {
          key: "device_type",
          label: this.$t("Pages.Account.Table.device_type"),
        },
        { key: "browser", label: this.$t("Pages.Account.Table.Browser") },
        { key: "ip", label: this.$t("Pages.Account.Table.Ip") },
        { key: "city", label: this.$t("Pages.Account.Table.City") },
        { key: "region", label: this.$t("Pages.Account.Table.Region") },
        { key: "country", label: this.$t("Pages.Account.Table.Country") },
        { key: "date", label: this.$t("Pages.Account.Table.Date") },
        { key: "status", label: this.$t("Pages.Account.Table.Status Device") },
        { key: "actions", label: this.$t("Pages.Account.Table.Operation") },
      ],
      checkAvailableReferralCode: "available",
      referral_users: [],
      referral_fields: [
        { key: "name", label: this.$t("Pages.Account.Table.Name User") },
        { key: "email", label: this.$t("Pages.Account.Table.Email") },
        { key: "mobile", label: this.$t("Pages.Account.Table.Mobile") },
        { key: "date_join", label: this.$t("Pages.Account.Table.Date Join") },
      ],
      routes: [
        "membership",
        "membership",
        "pay",
        "channel",
        "add",
        "channels",
        "channel",
        "accounting",
        "channel",
        "accounting",
        "channel",
        "accounting",
        "channel",
        ":channel_id",
        "channel",
        ":channel_id",
        "channel",
        ":channel_id",
        "channel",
        ":channel_id",
        "review",
        "check",
        "notification",
        "list",
        "account",
        "setting",
        "faq",
        "city",
        "city",
        "channel",
        "shop",
        "shop",
        "info",
        "shop",
        "pay",
        "shop",
        "purchased",
        "assets",
        "mentor",
        "channels",
        "tools",
        "assistant",
        "tools",
        "assistant",
        "tools",
        "assistant",
        "tools",
        "assistant",
        "tools",
        "assistant",
        "login",
        "forgetPassword",
        "resetPassword",
        "login",
        "callback",
        "signup",
        "error-404",
      ],
      base_url: "https://panel.master.tube/",
      membership: { id: 0 },
      status_mentoring: "no_request",
      PermissionRequestJoinToMentoring: false,
      addressDatas: {
        countries: [
          {
            value: 1,
            text: "ایران",
          },
        ],
        provinces: [],
        cities: [],
      },
      country: 1,
      province: null,
      city: null,
      postalCode: "",
      numberPlates: "",
      address: "",
      nameAddress: "",
      userAddresses: {
        addresses: [],
        default: null,
      },
      editAddress: null,
    };
  },
  props: {
    newAvatar: String,
  },
  watch: {
    card_number(to, from) {
      if (
        (from.length < to.length && from.length === 3 && to.length === 4) ||
        (from.length === 8 && to.length === 9) ||
        (from.length === 13 && to.length === 14)
      ) {
        this.card_number = this.card_number += " ";
      }
    },
  },
  created() {
    this.getUserProfile();
    this.getUserMembership();
    this.getStatusMentoring();
    this.$http.get("/api/check_login").then(({ data }) => {
      let permissions = [];
      if (data.permissions.length != 0) {
        data.permissions.map((item) => {
          permissions.push(item.name);
        });
      }
      if (permissions.includes("Request Join To Mentoring")) {
        this.PermissionRequestJoinToMentoring = true;
      }
    });
    this.getAddressDatas();
    this.getUserAddresses();
  },
  methods: {
    getUserProfile() {
      this.$http.get(`/api/user/getUserInfo`).then((response) => {
        this.optionsLocal.fullName = response.data.name;
        this.optionsLocal.email = response.data.email;
        this.optionsLocal.avatar = response.data.avatar;
        this.optionsLocal.mobile = response.data.mobile;
        this.optionsLocal.referral_token = response.data.referral_token;

        this.optionsLocal.national_code = response.data.national_code;
        if (this.optionsLocal.national_code) {
          this.nationalCodeDisabled = true;
        }
        if (this.optionsLocal.national_code && response.data.address) {
          this.optionsLocal.postal_code = response.data.address.postalCode;
          this.postalCodeDisabled = true;
        }
        if (this.optionsLocal.birth_date || response.data.birth_date) {
          console.log(
            new Date(response.data.birth_date).toLocaleDateString(
              "fa-IR-u-nu-latn"
            )
          );
          this.optionsLocal.birth_date = response.data.birth_date
            ? new Date(response.data.birth_date).toLocaleDateString(
                "fa-IR-u-nu-latn"
              )
            : "";
          this.birthDateDisabled = true;
        }

        this.default_bank_id = response.data.default_bank_id;
        if (response.data.bank_information) {
          this.items = response.data.bank_information;
          if (this.items.length < 2) {
            this.default_bank_id = this.items[0]?.id;
          }
        } else {
          this.items = [
            {
              account_number: integer,
              card_number: integer,
              sheba_number: integer,
            },
          ];
        }
      });
    },
    storeUserAvatar(image) {
      const data = new FormData();
      data.append("avatar", this.profileFile);
      this.$http
        .post(`/api/user/avatar/store`, data)
        .then((resp) => {
          this.makeToast(
            this.$t("Base.Alert.Successful"),
            this.$t("Base.Alert.success_change_profile"),
            "success"
          );
          this.getUserProfile();
        })
        .catch(() => {
          this.makeToast(
            this.$t("Base.Alert.Error"),
            this.$t("Base.Alert.profile_not_changed"),
            "danger"
          );
        });
    },
    sendMobileCode() {
      if (this.optionsLocal.newMobile.length >= 11 && this.event.codeSended) {
        this.$refs["modal-change-mobile"].show();
      } else if (this.optionsLocal.newMobile.length >= 11) {
        this.makeToast(
          this.$t("Base.Alert.Successful"),
          this.$t("Base.Alert.please_wait"),
          "success"
        );
        this.event.codeSended = true;
        const payload = {
          mobile: this.optionsLocal.newMobile,
        };
        this.$http
          .post(`/api/user/setting/mobile/code`, payload)
          .then((resp) => {
            this.event.codeSended = true;
            this.makeToast(
              this.$t("Base.Alert.Successful"),
              this.$t("Base.Alert.code_sent"),
              "success"
            );
            this.$refs["modal-change-mobile"].show();
          })
          .catch(() => {
            this.event.codeSended = false;
            this.makeToast(
              this.$t("Base.Alert.Error"),
              this.$t("Base.Alert.error_sending_code"),
              "danger"
            );
          });
      }
    },
    checkMobileCode() {
      if (this.optionsLocal.mobileCode != 0) {
        const payload = {
          mobile: this.optionsLocal.newMobile,
          code: this.optionsLocal.mobileCode,
        };
        this.$http
          .post(`/api/user/setting/check/mobile/code`, payload)
          .then((response) => {
            if (response.data.status) {
              this.makeToast(
                this.$t("Base.Alert.Successful"),
                this.$t("Base.Alert.phone_verified"),
                "success"
              );
              this.event.changeMobile = false;
              this.event.codeSended = false;
              this.getUserProfile();
              this.$refs["modal-change-mobile"].hide();
            } else {
              this.makeToast(
                this.$t("Base.Alert.Error"),
                this.$t("Base.Alert.incorrect_code"),
                "danger"
              );
            }
          })
          .catch(() => {
            this.makeToast(
              this.$t("Base.Alert.Error"),
              this.$t("Base.Alert.error_while_confirming"),
              "danger"
            );
          });
      }
    },
    changeMobile() {
      this.$refs["modal-change-mobile"].show();
      this.event.changeMobile = true;
      if (this.optionsLocal.newMobile.length >= 11) {
        this.sendMobileCode();
      }
    },
    updateDefaultBank(id) {
      if (this.default_bank_id != id) {
        this.default_bank_id = id;
        const payload = {
          id: id,
        };
        this.$http
          .post(`/api/user/setting/accounting/change-default`, payload)
          .then((response) => {
            if (response.data.status) {
              this.makeToast(
                this.$t("Base.Alert.Successful"),
                this.$t("Base.Alert.success_change_bank"),
                "success"
              );
              this.getUserProfile();
            } else {
              this.makeToast(
                this.$t("Base.Alert.Error"),
                this.$t("Base.Alert.error_default_bank"),
                "danger"
              );
            }
          });
      }
    },
    storeUserInfo() {
      if (
        this.checkAvailableReferralCode == "available" &&
        this.optionsLocal.referral_token != ""
      ) {
        this.makeToast(
          this.$t("Base.Alert.please_wait"),
          this.$t("Base.Alert.please_wait"),
          "success"
        );
        const payload = {
          full_name: this.optionsLocal.fullName,
          referral_token: this.optionsLocal.referral_token,
          national_code: this.optionsLocal.national_code,
          postal_code: this.optionsLocal.postal_code,
          birth_date:
            this.optionsLocal.birth_date != ""
              ? moment(this.optionsLocal.birth_date, "jYYYY/jM/jD").format(
                  "YYYY-MM-D"
                )
              : "",
        };
        this.$http
          .post(`/api/user/setting/info/store`, payload)
          .then((response) => {
            if (response.data.status) {
              this.makeToast(
                this.$t("Base.Alert.Successful"),
                this.$t("Pages.Account.Alert.info_saved"),
                "success"
              );
              this.getUserProfile();
            } else {
              this.makeToast(
                this.$t("Base.Alert.Error"),
                this.$t("Pages.Account.Alert.link_not_valid"),
                "danger"
              );
            }
          })
          .catch(() => {
            this.makeToast(
              this.$t("Base.Alert.Error"),
              this.$t("Base.Alert.error_while_confirming"),
              "danger"
            );
          });
      } else {
        this.makeToast(
          this.$t("Alert.Error"),
          this.$t("Pages.Account.Alert.link_not_valid"),
          "danger"
        );
      }
    },
    changeUserPassword() {
      const payload = {
        current_pass: this.optionsLocal.password,
        new_pass: this.optionsLocal.newPassword,
      };
      this.$http
        .post(`/api/user/setting/password/change`, payload)
        .then((response) => {
          if (response.data.status) {
            this.makeToast(
              this.$t("Alert.Successful"),
              this.$t("Pages.Account.Alert.info_saved"),
              "success"
            );
          } else {
            this.makeToast(
              this.$t("Base.Alert.Error"),
              this.$t("Pages.Account.Alert.complete_info"),
              "danger"
            );
          }
          this.optionsLocal.password = "";
          this.optionsLocal.newPassword = "";
          this.optionsLocal.repeatNewPassword = "";
        })
        .catch(() => {
          this.makeToast(
            this.$t("Base.Alert.Error"),
            this.$t("Base.Alert.error_while_confirming"),
            "danger"
          );
          this.optionsLocal.password = "";
          this.optionsLocal.newPassword = "";
          this.optionsLocal.repeatNewPassword = "";
        });
    },
    save() {
      if (this.profileFile) {
        this.storeUserAvatar(this.profileFile);
      }
      this.storeUserInfo();
    },
    changePassword() {
      if (
        this.optionsLocal.newPassword == this.optionsLocal.repeatNewPassword
      ) {
        this.changeUserPassword();
      } else {
        this.makeToast(
          this.$t("Base.Alert.Error"),
          this.$t("Base.Alert.pass_not_confirm"),
          "danger"
        );
      }
    },
    copyReferralCode() {
      navigator.clipboard.writeText(
        `${this.base_url}${this.optionsLocal.referral_token}`
      );
      this.makeToast(
        this.$t("Base.Alert.Successful"),
        this.$t("Pages.Account.Alert.success_copy_ref"),
        "success"
      );
    },
    initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight);
      });
    },
    selectAvatar(e) {
      this.optionsLocal.avatar = URL.createObjectURL(this.profileFile);
      this.newAvatar = URL.createObjectURL(this.profileFile);
    },
    openYoutube(channelId) {
      window.open(`https://www.youtube.com/channel/${channelId}`, "_blank");
    },
    getList() {
      this.$http.get(`/api/plan/purchase-records`).then((response) => {
        this.tableData = response.data;
      });
    },
    sendRecoveryPasswordLink() {
      this.$http
        .post(`/api/forgetPassword`, { email: this.optionsLocal.email })
        .then((resp) => {
          this.sendEmail = true;
          this.makeToast(
            this.$t("Base.Alert.Successful"),
            this.$t("Base.Alert.success_recovery_pass"),
            "success"
          );
        })
        .catch((error) => {
          if (error.response.status) {
            this.sendEmail = false;
            this.makeToast(
              this.$t("Alert.Error"),
              this.$t("Base.Alert.not_found_rec_pass"),
              "danger"
            );
          }
        });
    },
    getListDevices() {
      this.$http
        .get(`/api/user/device/active`)
        .then((response) => {
          this.devices = response.data;
          if (this.devices.length > 2) {
            this.devices = this.chunks(3);
          }
        })
        .catch((error) => {});
      this.getListDevicesLog();
    },
    chunks(array, size) {
      var results = [];
      while (array.length) {
        results.push(array.splice(0, size));
      }
      return results;
    },
    getListDevicesLog() {
      this.$http.get(`/api/user/device/log`).then((response) => {
        this.devices_log = response.data;
      });
    },
    DeleteDevice(id) {
      this.$http
        .post(`/api/user/device/logout/one`, { id: id })
        .then((response) => {
          if (response.data.status) {
            this.makeToast(
              this.$t("Base.Alert.Successful"),
              this.$t("Pages.Account.Alert.desired_removed"),
              "success"
            );
            this.getListDevices();
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          } else {
            this.makeToast(
              this.$t("Base.Alert.Error"),
              this.$t("Base.Alert.error_while_confirming"),
              "danger"
            );
          }
        })
        .catch(() => {
          this.makeToast(
            this.$t("Base.Alert.Error"),
            this.$t("Base.Alert.error_while_confirming"),
            "danger"
          );
        });
    },
    LogoutOthers() {
      this.$http
        .post(`/api/user/device/logout/others`)
        .then((response) => {
          if (response.data.status) {
            this.makeToast(
              this.$t("Base.Alert.Successful"),
              this.$t("Pages.Account.Alert.desired_removed"),
              "success"
            );
            this.getListDevices();
          } else {
            this.makeToast(
              this.$t("Base.Alert.Error"),
              this.$t("Base.Alert.error_while_confirming"),
              "danger"
            );
          }
        })
        .catch(() => {
          this.makeToast(
            this.$t("Base.Alert.Error"),
            this.$t("Base.Alert.error_while_confirming"),
            "danger"
          );
        });
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    },
    LogoutAll() {
      this.$http
        .post(`/api/user/device/logout/all`)
        .then((response) => {
          if (response.data.status) {
            this.makeToast(
              this.$t("Base.Alert.Successful"),
              this.$t("Pages.Account.Alert.desired_removed"),
              "success"
            );
            this.getListDevices();
          } else {
            this.makeToast(
              this.$t("Base.Alert.Error"),
              this.$t("Base.Alert.error_while_confirming"),
              "danger"
            );
          }
        })
        .catch(() => {
          this.makeToast(
            this.$t("Base.Alert.Error"),
            this.$t("Base.Alert.error_while_confirming"),
            "danger"
          );
        });
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    },
    ApprovedDevice(id) {
      this.$http
        .post(`/api/user/device/approved`, { id: id })
        .then((response) => {
          if (response.data.status) {
            this.makeToast(
              this.$t("Base.Alert.Successful"),
              this.$t("Pages.Account.Alert.device_add_approved"),
              "success"
            );
            this.getListDevices();
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          } else {
            this.makeToast(
              this.$t("Base.Alert.Error"),
              this.$t("Base.Alert.error_while_confirming"),
              "danger"
            );
          }
        })
        .catch(() => {
          this.makeToast(
            this.$t("Base.Alert.Error"),
            this.$t("Base.Alert.error_while_confirming"),
            "danger"
          );
        });
    },
    checkAvailableReferralToken() {
      if (!this.routes.includes(this.optionsLocal.referral_token)) {
        const payload = {
          referral_token: this.optionsLocal.referral_token,
        };
        this.$http
          .post(`/api/user/referral/token_available`, payload)
          .then((response) => {
            if (response.data.status) {
              this.checkAvailableReferralCode = "available";
            } else {
              this.checkAvailableReferralCode = "unavailable";
            }
          });
      } else {
        this.checkAvailableReferralCode = "unavailable";
      }
    },
    getListReferral() {
      this.$http.get(`/api/user/referral/list`).then((response) => {
        this.referral_users = response.data;
      });
    },
    getUserMembership() {
      this.$http.get("/api/dashboard/getUserPlan").then((response) => {
        this.membership = response.data;
      });
    },
    getStatusMentoring() {
      this.$http.get("/api/user/mentor/status").then((response) => {
        this.status_mentoring = response.data.status;
      });
    },
    requestJoinToMentoring() {
      this.$http.post(`/api/user/mentor/request`).then((response) => {
        if (response.data.status) {
          this.makeToast(
            this.$t("Base.Alert.Successful"),
            this.$t("Pages.Account.Alert.ment_req_regis"),
            "success"
          );
          this.getStatusMentoring();
        } else {
          this.makeToast(
            this.$t("Base.Alert.Error"),
            this.$t("Pages.Account.Alert.ment_req_regis_once"),
            "danger"
          );
        }
      });
    },
    filterReferralToken() {
      if (this.optionsLocal.referral_token) {
        this.optionsLocal.referral_token =
          this.optionsLocal.referral_token.replace(/[^\w\s]/gi, "");
      }
    },
    addNewCardNumber() {
      this.makeToast(
        this.$t("Base.Alert.please_wait"),
        this.$t("Base.Alert.please_wait"),
        "success"
      );
      const payload = {
        card_number: this.card_number.replaceAll(" ", ""),
      };
      this.$http
        .post(`/api/user/setting/accounting/add`, payload)
        .then((response) => {
          if (response.data.status) {
            this.makeToast(
              this.$t("Base.Alert.Successful"),
              this.$t("Pages.Account.Alert.success_bank_added"),
              "success"
            );
            this.getUserProfile();
            if (this.items.length == 1) {
              this.updateDefaultBank(this.items[0].id);
            }
          } else {
            this.makeToast(
              this.$t("Base.Alert.Error"),
              this.$t("Pages.Account.Alert.check_card_num"),
              "danger"
            );
          }
        });
    },
    addSpaceInCardNumber() {
      if (
        this.card_number.length === 4 ||
        this.card_number.length === 9 ||
        this.card_number.length === 14
      ) {
        this.card_number = this.card_number += " ";
      }
    },
    openModalAddNewCardNumber() {
      if (this.nationalCodeDisabled && this.birthDateDisabled) {
        this.$refs["modal-add-cardBank"].show();
      } else {
        this.makeToast(
          this.$t("Base.Alert.Error"),
          this.$t("Pages.Account.Alert.add_ncode_match_owner"),
          "danger"
        );
      }
    },
    deleteCardNumber(id) {
      this.$swal({
        title: this.$t("Base.Alert.are_sure"),
        text: this.$t("Base.Alert.wont_able_revert"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("Base.Alert.del_it"),
        cancelButtonText: this.$t("Base.Alert.Cancel"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$http
            .post(`/api/admin/manager/delete`, { id: id })
            .then((response) => {
              if (response.data.status) {
                this.$http
                  .post(`/api/user/setting/accounting/remove`, { id: id })
                  .then((response) => {
                    if (response.data.status) {
                      this.makeToast(
                        this.$t("Bsase.Alert.Successful"),
                        this.$t("Pages.Account.Alert.success_bank_remove"),
                        "success"
                      );
                      this.$swal({
                        icon: "success",
                        title: this.$t("Base.Alert.Deleted!"),
                        text: this.$t("Base.Alert.removed_success"),
                        customClass: {
                          confirmButton: "btn btn-success",
                        },
                      });
                      this.getUserProfile();
                    } else {
                      this.makeToast(
                        this.$t("Base.Alert.Error"),
                        this.$t("Pages.Account.Alert.bank_del_error"),
                        "danger"
                      );
                    }
                  })
                  .catch(() => {
                    this.makeToast(
                      this.$t("Base.Alert.Error"),
                      this.$t("Pages.Account.Alert.bank_del_error"),
                      "danger"
                    );
                  });
              } else {
                this.$swal({
                  icon: "error",
                  title: this.$t("Base.Alert.Deleted!"),
                  text: this.$t("Base.Alert.removed_error"),
                  customClass: {
                    confirmButton: "btn btn-danger",
                  },
                });
              }
            });
        }
      });
    },
    getAddressDatas() {
      this.$http.get("/api/user/get-provinces").then((response) => {
        if (response.data.status) {
          response.data.data.map((province) => {
            if (!this.addressDatas.provinces[province.country]) {
              this.addressDatas.provinces[province.country] = [];
            }
            this.addressDatas.provinces[province.country].push({
              value: province.id,
              text: province.name,
            });
          });
          this.addressDatas.provinces.map((item) => {
            item.unshift({
              value: null,
              text: this.$t("Base.Placeholder.select_opt"),
            });
          });
        }
      });
      this.$http.get("/api/user/get-cities").then((response) => {
        if (response.data.status) {
          response.data.data.map((city) => {
            if (!this.addressDatas.cities[city.province]) {
              this.addressDatas.cities[city.province] = [];
            }
            this.addressDatas.cities[city.province].push({
              value: city.id,
              text: city.name,
            });
          });
          this.addressDatas.cities.map((item) => {
            item.unshift({
              value: null,
              text: this.$t("Base.Placeholder.select_opt"),
            });
          });
        }
      });
    },
    async addAddress(bvModalEvt) {
      bvModalEvt.preventDefault();
      const okButton = this.$refs["address-modal"].$refs["ok-button"];
      const cancelButton = this.$refs["address-modal"].$refs["cancel-button"];
      okButton.innerText = this.$t("Pages.Account.Button.please_wait");
      if (this.province === null || this.city === null) {
        this.$swal({
          icon: "error",
          title: this.$t("Base.Alert.Error"),
          text: this.$t("Base.Alert.select_province_city"),
        });
        return;
      }
      if (this.postalCode === "" && this.numberPlates === "") {
        this.$swal({
          icon: "error",
          title: this.$t("Base.Alert.Error"),
          text: this.$t("Base.Alert.insert_postal_plates"),
        });
        return;
      }
      if (this.address.length < 10) {
        this.$swal({
          icon: "error",
          title: this.$t("Base.Alert.Error"),
          text: this.$t("Base.Alert.insert_address"),
        });
        return;
      }
      okButton.setAttribute("disabled", true);
      cancelButton.setAttribute("disabled", true);
      await this.$http
        .post("/api/user/set-address", {
          name: this.nameAddress,
          country: this.country,
          province: this.province,
          city: this.city,
          postalCode: this.postalCode,
          numberPlates: this.numberPlates,
          address: this.address,
        })
        .then((response) => {
          if (response.data.status) {
            this.$swal({
              icon: "success",
              title: this.$t("Base.Alert.Successful"),
              text: this.$t("Base.Alert.address_inserted"),
            });
            okButton.setAttribute("disabled", false);
            cancelButton.setAttribute("disabled", false);
            this.$nextTick(() => {
              this.$refs["address-modal"].toggle("#toggle-btn");
            });
            this.getUserAddresses();
            this.resetAddressForm();
          }
        })
        .catch((e) => {});
      okButton.setAttribute("disabled", false);
      cancelButton.setAttribute("disabled", false);
    },
    resetAddressForm() {
      this.province = null;
      this.city = null;
      this.postalCode = "";
      this.numberPlates = "";
      this.address = "";
    },
    getUserAddresses() {
      this.$http.get("/api/user/get-addresses").then((response) => {
        if (response.data.status) {
          this.userAddresses.addresses = response.data.data.addresses;
          this.userAddresses.default = response.data.data.default;
        }
      });
    },
    updateDefaultAddress(event, id) {
      if (this.userAddresses.default === id) return;
      if (
        event.target.nodeName === "BUTTON" ||
        event.target.nodeName === "SPAN"
      )
        return;
      this.$http
        .post("/api/user/set-default-address", { address: id })
        .then((response) => {
          if (response.data.status) {
            this.userAddresses.default = id;
            this.makeToast(
              this.$t("Base.Alert.Successful"),
              this.$t("Base.Alert.success_update_address"),
              "success"
            );
          }
        });
    },
    deleteAddress(id) {
      this.$swal({
        icon: "warning",
        title: this.$t("Base.Alert.are_sure"),
        text: this.$t("Base.Alert.on_del_address"),
        confirmButtonText: this.$t("Base.Alert.del_it"),
        showCancelButton: true,
        cancelButtonText: this.$t("Base.Alert.Cancel"),
      }).then((result) => {
        if (result.value) {
          this.$http
            .post("/api/user/delete-address", { address: id })
            .then((response) => {
              if (response.data.status) {
                this.userAddresses.addresses.map((item, index) => {
                  if (item.id === id) {
                    this.userAddresses.addresses.splice(index, 1);
                    this.$swal({
                      icon: "success",
                      title: this.$t("Base.Alert.Successful"),
                      text: this.$t("Base.Alert.removed_success"),
                    });
                  }
                });
              } else {
                this.$swal({
                  icon: "error",
                  title: this.$t("Base.Alert.Error"),
                  text: this.$t("Base.Alert.removed_error"),
                });
              }
            });
        }
      });
    },
    editUserAddress(id) {
      this.userAddresses.addresses.map((item, index) => {
        if (item.id === id) {
          this.editAddress = {
            id: item.id,
            name: item.name,
            country: item.country_id,
            province: item.province_id,
            city: item.city_id,
            postalCode: item.postal_code,
            numberPlates: item.number_plates,
            address: item.address,
          };
          this.$nextTick(() => {
            this.$refs["edit-address-modal"].toggle("#toggle-btn");
          });
        }
      });
    },
    async updateUserAddress(bvModalEvt) {
      bvModalEvt.preventDefault();
      const okButton = this.$refs["edit-address-modal"].$refs["ok-button"];
      const cancelButton =
        this.$refs["edit-address-modal"].$refs["cancel-button"];
      okButton.innerText = this.$t("Pages.Account.Button.please_wait");
      okButton.setAttribute("disabled", true);
      cancelButton.setAttribute("disabled", true);
      if (
        this.editAddress.province === null ||
        this.editAddress.city === null
      ) {
        this.$swal({
          icon: "error",
          title: this.$t("Base.Alert.Error"),
          text: this.$t("Base.Alert.select_province_city"),
        });
        return;
      }
      if (
        this.editAddress.postalCode === "" &&
        this.editAddress.numberPlates === ""
      ) {
        this.$swal({
          icon: "error",
          title: this.$t("Base.Alert.Error"),
          text: this.$t("Base.Alert.insert_postal_plates"),
        });
        return;
      }
      if (this.editAddress.address.length < 10) {
        this.$swal({
          icon: "error",
          title: this.$t("Base.Alert.Error"),
          text: this.$t("Base.Alert.insert_address"),
        });
        return;
      }

      await this.$http
        .post(
          `/api/user/update-address/${this.editAddress.id}`,
          this.editAddress
        )
        .then((response) => {
          if (response.data.status) {
            this.$swal({
              icon: "success",
              title: this.$t("Base.Alert.Successful"),
              text: this.$t("Base.Alert.address_edited"),
            });
            this.$nextTick(() => {
              this.$refs["edit-address-modal"].toggle("#toggle-btn");
            });
            this.getUserAddresses();
            this.resetEditAddressForm();
          }
        });
      okButton.setAttribute("disabled", false);
      cancelButton.setAttribute("disabled", false);
    },
    resetEditAddressForm() {
      this.editAddress = null;
    },
  },
};
</script>
<style>
.vpd-icon-btn {
  background-color: #db2d36 !important;
}
</style>
<style scoped>
.addressCart .border-info {
  border-radius: 0.358rem;
  margin: 0;
  margin-bottom: 2rem;
  border: 3px solid #3ea6ff !important;
  background-color: rgba(62, 166, 255, 0.12) !important;
}

.addressCart .card {
  transition: 0.3s;
  cursor: pointer !important;
}

.addressCart .card:hover {
  background-color: rgba(62, 166, 255, 0.12) !important;
  transition: 0.3s;
  cursor: pointer !important;
}

.card.border-info .card-title {
  color: #3ea6ff !important;
}

.card.border-info .card-title hr {
  border-color: #3ea6ff;
}
.alert-width {
  width: 100%;
  display: flex;
  align-items: center;
  margin-right: 20px;
}
.add-btn {
  display: flex;
  align-items: center;
}
.add-btn span {
  display: block;
  width: max-content;
}
.alert-area {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 576px) {
  .alert-area {
    display: block;
  }
  .add-btn {
    margin: 15px auto 0 auto;
  }
}
</style>
